"use client";
export {
	createScrollAreaScope,
	//
	ScrollArea,
	ScrollAreaViewport,
	ScrollAreaScrollbar,
	ScrollAreaThumb,
	ScrollAreaCorner,
	//
	Root,
	Viewport,
	Scrollbar,
	Thumb,
	Corner,
} from "./ScrollArea";
export type {
	ScrollAreaProps,
	ScrollAreaViewportProps,
	ScrollAreaScrollbarProps,
	ScrollAreaThumbProps,
	ScrollAreaCornerProps,
} from "./ScrollArea";
