"use client";
export {
	createTooltipScope,
	//
	TooltipProvider,
	Tooltip,
	TooltipTrigger,
	TooltipPortal,
	TooltipContent,
	TooltipArrow,
	//
	Provider,
	Root,
	Trigger,
	Portal,
	Content,
	Arrow,
} from "./Tooltip";
export type {
	TooltipProps,
	TooltipTriggerProps,
	TooltipPortalProps,
	TooltipContentProps,
	TooltipArrowProps,
} from "./Tooltip";
